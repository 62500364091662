import { Component, OnInit, ViewChild  } from '@angular/core';
import { LangService } from './services/lang.service';
import { TrackingService } from './services/tracking.service';
import { ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    @ViewChild('cookieLaw') private cookieLawEl: any;
    @ViewChild('cookiesPreferences', {read: ElementRef}) cookiesPreferences: ElementRef;
    @ViewChild('cookiesStatistics', {read: ElementRef}) cookiesStatistics: ElementRef;

    constructor(private lang: LangService,
                private trackingService: TrackingService,
                private router: Router) {
        this.lang.loadSessionLang();
    }
    ngOnInit() {
        this.setUpAnalytics();
    }

    public acceptcookies(): void {
        if (this.cookiesPreferences.nativeElement.checked) {
            this.trackingService.setPermissionTracking('preferences');
        }
        if (this.cookiesStatistics.nativeElement.checked) {
            this.trackingService.setPermissionTracking('statistics');
        }
        if (this.cookiesStatistics.nativeElement.checked && this.cookiesPreferences.nativeElement.checked) {
            this.trackingService.setPermissionTracking('all');
        }
        if (!this.cookiesStatistics.nativeElement.checked && !this.cookiesPreferences.nativeElement.checked) {
            this.trackingService.setPermissionTracking('dismiss');
        }
        this.cookieLawEl.dismiss();
    }

    setUpAnalytics() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                gtag('config', 'GTM-M3MKLTL2',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
            });
    }

}
